import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Les anciens navigateurs",
  "description": "Support des anciens navigateurs grâce à différentes méthodes.",
  "date": "2013-10-12T00:00:00.000Z",
  "path": "/articles/les-anciens-navigateurs/",
  "archive": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`La vie serait beaucoup plus simple si on n’avait plus à assurer le support des antiquités que sont IE6, 7 et 8. Malheureusement au quotidien ça n’est pas toujours possible; on se retrouve souvent contraints de réaliser le grand écart entre moderne et vieux. Si l’on souhaite se tourner vers une conception moderne en responsive design, comment s’assurer du meilleur support possible ?`}</p>
    <p className="info">
  Cet article s’attardera uniquement sur la gestion des media queries en
  responsive design; je ne parlerai pas ici d’autres propriétés CSS3 non
  supportés par certains de ces navigateurs, par exemple flexbox, border-box
  etc.
    </p>
    <p>{`J’ai identifié ci-dessous différentes approches qui permettent de partir d’un modèle de design `}<em parentName="p">{`mobile first`}</em>{` pour s’assurer que notre site ressemble quand même à quelque chose sur les vieux dinosaures du web.`}</p>
    <h2>{`Ne rien faire`}</h2>
    <p>{`Approche la plus simple, puisqu’elle ne demande quasiment aucun effort de notre part. Les `}<em parentName="p">{`media queries`}</em>{` n’étant pas compris par IE`}{`<`}{`9, le navigateur affichera la version mobile du site. Pour rendre tout ça un peu moins moche, rien n’empêche de limiter la largeur maximum du contenu à coups de `}<code parentName="p" {...{
        "className": "text"
      }}>{`max-width`}</code>{` et de `}<a parentName="p" {...{
        "href": "http://briangelhaus.com/blog/target-ie-in-css/"
      }}>{`commentaires conditionnels`}</a>{`.`}</p>
    <p>{`A priori, cette approche n’est pas idéale, puisque les utilisateurs risquent d’être un peu perturbés par le fait de naviguer sur une version mobile. Ceci dit c’est toujours mieux qu’un site sur lequel il est impossible de naviguer.`}</p>
    <h2>{`Cibler IE<9 en CSS`}</h2>
    <p>{`Grâce aux `}<a parentName="p" {...{
        "href": "http://briangelhaus.com/blog/target-ie-in-css/"
      }}>{`commentaires conditionnels`}</a>{` dont je parlais ci-dessus, il est assez facile de créer des classes spécifiques, par exemple :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "css"
      }}><code parentName="pre" {...{
          "className": "css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.ie8 .header`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  // bla bla bla
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Cette approche est toutefois assez fastidieuse, elle nécessite en effet de réecrire toutes les propriétés css comprises dans les `}<em parentName="p">{`media queries`}</em>{` qui se situent autour des dimensions "habituelles" de ces navigateurs (1000-1200 px). Niveau maintenance, ça n’est pas non plus idéal puisque l’on doit mettre à jour le CSS spécifique à IE en même temps que le CSS global.`}</p>
    <h2>{`Cibler IE`}{`<`}{`9 avec Sass`}</h2>
    <p>{`Une technique très intéressante, `}<a parentName="p" {...{
        "href": "http://jakearchibald.github.io/sass-ie/"
      }}>{`démontrée ici`}</a>{` par `}<a parentName="p" {...{
        "href": "http://jakearchibald.com/"
      }}>{`Jake Archibald`}</a>{` (Google Chrome). Elle tire parti de la puissance de Sass et de ses `}<code parentName="p" {...{
        "className": "text"
      }}>{`@mixin`}</code>{` pour créer une feuille de styles spécifique à IE`}{`<`}{`9.`}</p>
    <p>{`Il est ainsi facile de définir les `}<em parentName="p">{`media queries`}</em>{` que l’on souhaite intégrer dans celle-ci, par exemple toutes celles qui sont inférieures à 1200px. La feuille de style ajoutera ensuite tous les styles des `}<em parentName="p">{`media queries`}</em>{` jusqu’à 1200px.`}</p>
    <p>{`Cette méthode implique en revanche d’écrire des `}<em parentName="p">{`media queries`}</em>{` propres à chaque élément, par exemple :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "css"
      }}><code parentName="pre" {...{
          "className": "css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.element`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` red`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  `}<span parentName="code" {...{
            "className": "token atrule"
          }}><span parentName="span" {...{
              "className": "token rule"
            }}>{`@media`}</span>{` screen `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`and`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token property"
            }}>{`min-width`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 1100px`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` blue`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Un "inconvénient" quasiment négligeable si vous prenez soin de minifier et gzipper vos CSS, sans compter un gros gain en maintenabilité.`}</p>
    <h2>{`Respond.js`}</h2>
    <p>{`Contrairement aux techniques ci-dessus, ce polyfill très léger (3ko) assure le support des `}<em parentName="p">{`media queries`}</em>{` pour `}<code parentName="p" {...{
        "className": "text"
      }}>{`max-width`}</code>{` et `}<code parentName="p" {...{
        "className": "text"
      }}>{`min-width`}</code>{`. On n’a donc plus à se soucier de créer une feuille de styles spécifique puisque le script se chargera de tout. Le fichier devra être intégré dans le `}<code parentName="p" {...{
        "className": "text"
      }}>{`<head>`}</code>{` de la page pour qu’il soit exécuté le plus tôt possible.`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/scottjehl/Respond"
      }}>{`Voir Respond.js sur Github`}</a></p>
    <p>{`Le seul bémol que je vois à son utilisation est qu’il doit être hébergé sur le même serveur que la page; si ça n’est pas le cas il faudra uploader une page proxy pour permettre la communication cross-domain (cf. l’exemple sur Github).`}</p>
    <p className="info">
  Mise à jour (29/10/2015) : étant donné que ces navigateurs ne sont plus
  maintenus, cet article peut être considéré comme obsolète :)
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      